import { SearchClassification } from '@wildscreen/api/src/apiClients';
import { capitalizeFirstLetter, TTaxonomy } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { Gallery } from '../../components/gallery';
import { TaxonomyPod } from '../../components/pod';
import { Related } from '../../components/related';
import { TabController } from '../../components/tabController';
import { getTitle } from '../../helpers/metaHelpers';

import styles from './taxonomy.module.scss';

export const TaxonomyView: React.FC = () => {
  const { wildscreenId } = useParams<TTaxonomy>();
  const isMobile = useIsMobile();

  const [{ data, isFetching }] = apiHooks.ark.arkClassificationDocumentIdGet.useQuery({
    parameters: {
      id: wildscreenId,
    },
    cacheKey: 'id',
  });

  const searchData = data?.data as unknown as SearchClassification;

  const title = React.useMemo(() => {
    return capitalizeFirstLetter(searchData?.friendlyName || searchData?.scientificNames?.[0] || '');
  }, [searchData?.friendlyName, searchData?.scientificNames]);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
        <meta name="description" content={`Explore ${title} on Wildscreen ARK`} />
      </Helmet>
      <div className={styles.container}>
        {!isMobile && <BackButton />}
        <div className={styles.content}>
          <TaxonomyPod data={searchData} isLoading={isFetching} />
          <TabController
            related={<Related searchData={searchData} wildscreenId={wildscreenId} />}
            gallery={<Gallery searchData={searchData} wildscreenId={wildscreenId} maxImageWidth={444} />}
          />
        </div>
      </div>
    </>
  );
};
