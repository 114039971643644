import { useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { UserAgreement } from '../../components/userAgreement';
import { getTitle } from '../../helpers/metaHelpers';

export const TermsAndConditions: React.FC = () => {
  const { arkive } = useDictionary('en');
  const { heading, description, content } = arkive.unauthenticated.views.termsAndConditions;

  return (
    <>
      <Helmet>
        <title>{getTitle(heading)}</title>
        <meta name="description" content={description} />
      </Helmet>
      <UserAgreement heading={heading}>{content()}</UserAgreement>
    </>
  );
};
