import { ArmstrongConfigProvider } from '@rocketmakers/armstrong';
import { routes } from '@wildscreen/core/src/core';
import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Shell } from './components/_layout/shell';
import { AboutUs } from './views/aboutUs';
import { AssetView } from './views/asset';
import { AssetTagView } from './views/assetTag';
import { ClassificationTagView } from './views/classificationTag';
import { EducationResources } from './views/educationResources';
import { Error404 } from './views/error404';
import { Explore } from './views/explore';
import { ExploreAssetView } from './views/exploreAsset';
import { ExploreClassificationView } from './views/exploreClassification';
import { ExploreTaxonomyView } from './views/exploreTaxonomy/exploreTaxonomy';
import { HomeView } from './views/home/home';
import { PrivacyPolicy } from './views/privacyPolicy';
import { TaxonomyView } from './views/taxonomy';
import { TermsAndConditions } from './views/termsAndConditions';

export const Routing: React.FC = () => {
  const location = useLocation();
  return (
    <ArmstrongConfigProvider>
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Shell />}>
            <Route path={routes.authenticated.arkive.root()} element={<HomeView />} />
            <Route path={routes.authenticated.arkive.explore()} element={<Explore />} />
            <Route
              path={routes.authenticated.arkive.assetProperty({ targetProperty: ':targetProperty' })}
              element={<AssetTagView />}
            />
            <Route
              path={routes.authenticated.arkive.classificationProperty({ targetProperty: ':targetProperty' })}
              element={<ClassificationTagView />}
            />
            <Route
              path={routes.authenticated.arkive.exploreAsset({
                assetProperty: ':assetProperty',
                assetSearchTerm: ':assetSearchTerm',
              })}
              element={<ExploreAssetView />}
            />
            <Route
              path={routes.authenticated.arkive.exploreClassification({
                classificationProperty: ':classificationProperty',
                classificationSearchTerm: ':classificationSearchTerm',
              })}
              element={<ExploreClassificationView />}
            />
            <Route
              path={routes.authenticated.arkive.exploreTaxonomy({
                taxonomyRank: ':taxonomyRank',
                taxonomySearchTerm: ':taxonomySearchTerm',
              })}
              element={<ExploreTaxonomyView />}
            />
            <Route path={routes.authenticated.arkive.asset({ assetId: ':assetId' })} element={<AssetView />} />
            <Route
              path={routes.authenticated.arkive.taxonomy({ wildscreenId: ':wildscreenId' })}
              element={<TaxonomyView />}
            />
            <Route path={routes.authenticated.arkive.aboutUs()} element={<AboutUs />} />
            <Route path={routes.authenticated.arkive.educationResources()} element={<EducationResources />} />
            <Route path={routes.authenticated.arkive.termsAndConditions()} element={<TermsAndConditions />} />
            <Route path={routes.authenticated.arkive.privacyPolicy()} element={<PrivacyPolicy />} />
            <Route path={routes.authenticated.arkive.error404()} element={<Error404 />} />
            <Route path="*" element={<Navigate replace to={routes.authenticated.arkive.error404()} />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </ArmstrongConfigProvider>
  );
};
