import { ArkResource } from '@wildscreen/api/src/apiClients';
import { dictionary, formatAgeCategory } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { apiHooks } from '../../api';
import { EducationResource } from '../../components/educationResource';
import { IPodTag, PodTagContainer } from '../../components/pod/components/podTagContainer';
import { useHideSearch } from '../../contexts/header';
import { getTitle } from '../../helpers/metaHelpers';

import styles from './educationResources.module.scss';

export const EducationResources: React.FC = () => {
  useHideSearch();
  const [{ data }] = apiHooks.ark.arkResourceSearchPost.useQuery({
    parameters: {
      resourceSearchQuery: {
        from: 0,
        size: 1000, // no paging yet for this screen
      },
    },
  });

  const groupedByAgeCat = React.useMemo(() => {
    if (!data) {
      return {};
    }
    return (
      data?.data?.results?.reduce((acc, resource) => {
        const cat = resource.ageCategory ?? 'Unknown';
        if (!acc[cat]) {
          acc[cat] = [];
        }
        acc[cat].push(resource);
        return acc;
      }, {} as Record<string, ArkResource[]>) ?? {}
    );
  }, [data]);

  const getResourceTags = React.useCallback((resource: ArkResource): IPodTag[] => {
    const { biologicalThemes, habitats, landRegions, threats, actions } = resource;
    const tagData = {
      biologicalThemes,
      habitats,
      landRegions,
      threats,
      actions,
    };
    return Object.entries(tagData).reduce((acc, [key, value]) => {
      if (value) {
        acc.push(...value.map(v => ({ type: key, tag: v })));
      }
      return acc;
    }, [] as IPodTag[]);
  }, []);

  return (
    <>
      <Helmet>
        <title>{getTitle(dictionary.en.arkive.unauthenticated.views.educationResources.title)}</title>
        <meta name="description" content={dictionary.en.arkive.unauthenticated.views.educationResources.description} />
      </Helmet>
      <div className={styles.container}>
        <h2>{dictionary.en.arkive.unauthenticated.views.educationResources.title}</h2>
        {Object.entries(groupedByAgeCat).map(([key, resources]) => {
          return (
            <React.Fragment key={key}>
              <h3>{formatAgeCategory(key, true)}</h3>
              <div className={styles.resourceGrid}>
                {resources.map(resource => (
                  <EducationResource
                    key={resource.resourceId}
                    heading={resource.title}
                    ageRange={resource.ageCategory}
                    description={resource.description}
                    downloadUrl={resource.location || undefined}
                    downloadFilename={resource.resourceName}
                    resourceType={resource.type}
                    subjects={resource.subjects}
                    tags={<PodTagContainer tags={getResourceTags(resource)} maxTags={3} />}
                  />
                ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
