import { routes, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { ErrorPage } from '../../components/errorPage';

export const Error404: React.FC = () => {
  const { arkive } = useDictionary('en');
  const { errorCode, errorMessage, errorDescription, backButtonCaption } = arkive.unauthenticated.views.error404;

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{errorCode}</title>
        <meta rel="index" />
      </Helmet>
      <ErrorPage
        title={errorCode}
        subtitle={errorMessage}
        description={errorDescription}
        resetButtonCaption={backButtonCaption}
        resetButtonOnClick={() => navigate(routes.authenticated.arkive.root())}
      />
    </>
  );
};
