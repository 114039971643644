import * as React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { apiHooks } from '../../api';
import { GalleryAsset } from '../galleryAsset';

import styles from './homepageGallery.module.scss';

export const HomepageGallery: React.FC = () => {
  const [{ data, isFetching }] = apiHooks.ark.arkAssetSearchPost.useQuery({
    parameters: {
      assetSearchQuery: { isHomepageWorthy: true, jitter: true, hasPromotionLink: null, from: 0, size: 20 },
    },
  });

  return (
    <div className={styles.homepageGalleryContainer}>
      <div className={styles.inner}>
        <h3>Browse our Gallery</h3>
        <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 500: 2, 800: 3, 1000: 4 }}>
          <Masonry>
            {isFetching &&
              Array.from({ length: 20 }).map((_, index) => (
                <GalleryAsset key={index} maxWidth={500} isFetching={true} />
              ))}
            {data?.data.results?.map((asset, i) => (
              <GalleryAsset maxWidth={500} key={asset.assetId} asset={asset} mode="home" lazyLoad={i > 3} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};
