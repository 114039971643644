import { useDictionary } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { HomeBanner } from '../../components/homeBanner';
import { HomepageGallery } from '../../components/homepageGallery';
import { useShowSearch } from '../../contexts/header';
import { getTitle } from '../../helpers/metaHelpers';

import styles from './home.module.scss';

export const HomeView: React.FC = () => {
  useShowSearch();
  const { arkive } = useDictionary('en');

  return (
    <div data-testid={testIds.authenticated.views.welcome.view} className={styles.container}>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={arkive.unauthenticated.views.homeBanner.description} />
      </Helmet>
      <HomeBanner />
      <HomepageGallery />
      <Outlet />
    </div>
  );
};
