import { AssetListProperty, ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { useDictionary } from '@wildscreen/core/src/core';
import { motion } from 'framer-motion';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { AssetCollectionTiles } from '../../components/assetCollectionTiles';
import { ClassificationCollectionTiles } from '../../components/classificationCollectionTiles';
import { Search } from '../../components/search';
import { TaxonomyCollectionTiles } from '../../components/taxonomyCollectionTiles';
import { useHeaderContext, useShowSearch } from '../../contexts/header';
import { getTitle } from '../../helpers/metaHelpers';

import styles from './explore.module.scss';

export const Explore: React.FC = () => {
  const { setIsSearchOpen } = useHeaderContext();
  const [foundResults, setFoundResults] = React.useState(false);

  const { arkive } = useDictionary('en');

  useShowSearch();

  React.useEffect(() => {
    setIsSearchOpen(true);
    return () => {
      setIsSearchOpen(false);
    };
  }, [setIsSearchOpen]);

  return (
    <>
      <Helmet>
        <title>{getTitle(arkive.unauthenticated.views.explore.title)}</title>
        <meta name="description" content={arkive.unauthenticated.views.explore.description} />
      </Helmet>
      <motion.div
        className={styles.exploreContainer}
        animate={{ y: 0, transition: { duration: 0.3 } }}
        initial={{ y: -window.innerHeight }}
        exit={{ y: -window.innerHeight, transition: { duration: 0.3 } }}
      >
        <Search onFoundResults={setFoundResults} />

        {!foundResults && (
          <>
            {/* Taxonomy */}
            <TaxonomyCollectionTiles taxonRank="Species" />
            <TaxonomyCollectionTiles taxonRank="Class" />
            <TaxonomyCollectionTiles taxonRank="Order" />

            <AssetCollectionTiles property={AssetListProperty.Behaviours} />
            <ClassificationCollectionTiles property={ClassificationListProperty.Adaptations} />
            <AssetCollectionTiles property={AssetListProperty.LifeStages} />
            <ClassificationCollectionTiles property={ClassificationListProperty.BiologicalThemes} />
            <ClassificationCollectionTiles property={ClassificationListProperty.Threats} />
            <ClassificationCollectionTiles property={ClassificationListProperty.Actions} />
            <ClassificationCollectionTiles property={ClassificationListProperty.Habitats} />
            <ClassificationCollectionTiles property={ClassificationListProperty.LandRegions} />
          </>
        )}
      </motion.div>
    </>
  );
};
