import { TaxonRank } from '@wildscreen/api/src/apiClients';
import { TAsset } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { Gallery } from '../../components/gallery';
import { AssetPod } from '../../components/pod/assetPod';
import { RelatedAssets } from '../../components/related/relatedAssets';
import { TabController } from '../../components/tabController';
import { getTitle } from '../../helpers/metaHelpers';

import styles from './asset.module.scss';

export const AssetView: React.FC = () => {
  const { assetId } = useParams<TAsset>();
  const isMobile = useIsMobile();

  const [{ data: assetData, isFetching }] = apiHooks.ark.arkAssetDocumentIdGet.useQuery({
    parameters: {
      id: assetId,
    },
    cacheKey: 'id',
  });

  const creditName = assetData?.data?.credit || assetData?.data?.libraryCredit?.name;

  const title = React.useMemo(() => {
    if (!assetData?.data) {
      return '';
    }
    if (assetData?.data?.promotionLink) {
      return assetData.data.title;
    }

    return `${assetData.data.title}${creditName ? ` by ${creditName}` : ''}`;
  }, [assetData?.data, creditName]);

  const description = React.useMemo(() => {
    if (!assetData?.data) {
      return '';
    }

    if (assetData?.data?.promotionLink) {
      return `${assetData.data.title} on Wildscreen ARK`;
    }

    if (assetData?.data?.isVideo) {
      return `Watch ${assetData.data.title}${creditName ? ` by ${creditName}` : ''} on Wildscreen ARK`;
    }
    return `View ${assetData.data.title}${creditName ? ` by ${creditName}` : ''} on Wildscreen ARK`;
  }, [assetData?.data, creditName]);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className={styles.container}>
        {!isMobile && <BackButton />}
        <div className={styles.content}>
          <AssetPod data={assetData?.data} isLoading={isFetching} />
          <TabController
            related={<RelatedAssets assetId={assetId} searchData={assetData?.data} isFetching={isFetching} />}
            gallery={
              <Gallery
                maxImageWidth={444}
                searchData={assetData?.data}
                wildscreenId={assetId}
                overrideTaxonData={{
                  currentTaxonRank: TaxonRank.Species.toLowerCase(),
                  currentTaxonValue: assetData?.data?.inheritance?.species?.values?.[0] ?? '',
                }}
              />
            }
          />
        </div>
      </div>
    </>
  );
};
